import { domainUrl } from './services/domain-url';
import { version } from './infos/infos';
import { disconnect } from './services/disconnect';
import { redirectToDefaultApp } from './services/redirect-to-default-page';
import { appAccess } from './services/app-access';

export const services: any = {
    domainUrl: domainUrl,
    appAccess: appAccess,
    disconnect: disconnect,
    redirectToDefaultApp: redirectToDefaultApp
};

export const infos = {'version': version};
