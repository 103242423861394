class DomainUrlService {

    public static getInstance(): DomainUrlService {
        if (!this.INSTANCE) {
            this.INSTANCE = new DomainUrlService();
        }
        return this.INSTANCE;
    }

    private static APPS_ON_NEW_ROUTER = [ 'calendar', 'customerportal', 'covenant', 'hedging', 'api/analysis', 'gp', 'cdc', 'access', 'portfolio'];
    private static SHARED_DOMAIN = 'apps';

    private static INSTANCE: any;

    private static DEV_SEPARATOR = '---';
    private static PROD_SEPARATOR = '.';

    private staticDomain: string;
    private jwtDomain: string;
    private protocol: string;

    constructor() {
        this.protocol = this.getProtocol() + '//';

        const domain = window.location.host;
        const indexDevSeparator = domain.indexOf(DomainUrlService.DEV_SEPARATOR);
        const indexProdSeparator = domain.indexOf(DomainUrlService.PROD_SEPARATOR);
        if (indexDevSeparator !== -1) {
            this.staticDomain = domain.substring(indexDevSeparator);
            this.jwtDomain = this.staticDomain.substring(this.staticDomain.indexOf(".") + 1);
        } else {
            this.staticDomain = domain.substring(indexProdSeparator);
            this.jwtDomain = domain.substring(indexProdSeparator + 1);
        }
    }

    public getDomainFor(prefix: string) {
        if (DomainUrlService.APPS_ON_NEW_ROUTER.indexOf(prefix) > -1) {
            return this.protocol + DomainUrlService.SHARED_DOMAIN + this.staticDomain + '/' + prefix;
        } else {
            return this.protocol + prefix + this.staticDomain;
        }
    }

    public getJwtDomain() {
        return this.jwtDomain;
    }

    private getProtocol() {
        return window.location.protocol;
    }
}

export const domainUrl = DomainUrlService.getInstance();
