import { domainUrl } from '../services/domain-url';
import { disconnect } from './disconnect';

export const redirectToDefaultApp = () => {

    const debtDomain = domainUrl.getDomainFor('debt');

    const xhr = new XMLHttpRequest();
    xhr.open('GET', debtDomain + '/api/v1/applications/default', true);
    xhr.withCredentials = true;

    xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200) {
                window.location.href = JSON.parse(xhr.response).uri;
            } else {
                disconnect(false);
            }
        }
    };
    xhr.send(null);
};