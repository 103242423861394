import { domainUrl } from '../services/domain-url';

class AppAccessService {

    private static INSTANCE: AppAccessService;
    public static getInstance(): AppAccessService {
        if (!this.INSTANCE) {
            this.INSTANCE = new AppAccessService();
        }
        return this.INSTANCE;
    }

    private appDomain = domainUrl.getDomainFor('apps');
    private loggOnPath = '/api/access/v1/logged_in';
    private loggOffPath = '/api/access/v1/disconnect';

    public getLogonUrl(): string {
        return this.appDomain + this.loggOnPath;
    }

    public getLogoffUrl(): string {
        return this.appDomain + this.loggOffPath;
    }

    public getLogoffUrlWithRedirectUrlParam(): string {
        return this.appDomain + this.loggOffPath + '?redirectUrl=' + this.encodeUrlToBase64();
    }

    public logoff(): void {
        this.setPageUrl(this.getLogoffUrl());
    }

    public logoffWithRedirectUrlParam(): void {
        this.setPageUrl(this.getLogoffUrlWithRedirectUrlParam());
    }

    private encodeUrlToBase64(): string {
        const currentPageUrl = window.location.href;
        return btoa(currentPageUrl);
    }

    private setPageUrl(url: string): void {
        window.location.href = url;
    }

}

export const appAccess = AppAccessService.getInstance();
